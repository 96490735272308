<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <br />
    <div>
      <h1 v-html="'Elenco scuole-accordi'" />
      <p>
        Si ricorda che è obbligatorio compilare la sezione "Inserimento scuole e
        accordi" prima della chiusura di ciascun corso.
      </p>
    </div>

    <!--<v-alert border="top" colored-border type="info" elevation="2">
      In corrispondenza della colonna “Integra” è possibile integrare un
      accordo-scuola inserendo nuovi studenti e nuovi corsi per la stessa
      scuola. <br />
      L’inserimento dell’integrazione sarà possibile solo se la somma tra il
      valore del campo 'numero_corsi' nell'accordo-scuola specifico e i valori
      del campo omonimo di tutti i sotto-accordi associati è uguale al numero
      dei corsi terminati totali, in riferimento all'accordo-scuola specifico.
    </v-alert>-->
    <v-card elevation="4" v-if="loadingPage">
      <v-card-title>
        Elenco accordi-scuole per anno accademico {{ this.anno }}/{{
          this.anno + 1
        }}
      </v-card-title>
      <v-card-subtitle v-if="programmaOrientamento">
        CUP del programma orientamento associato:
        <b>{{ programmaOrientamento.cup }}</b></v-card-subtitle
      >

      <v-row>
        <v-col class="mx-4">
          <router-link
            v-if="
              this.programmaOrientamento != null &&
              permessiOperazione > 0 &&
              finestraCompilazione
            "
            class="v-btn primary pa-2"
            :to="{
              name: 'aggiungi-scuola-accordi',
              params: {
                capofila: capofila,
                idAccordo: null,
                operazione: 'inserimento',
              },
            }"
          >
            <v-icon> mdi-plus </v-icon>
            Inserisci un nuovo Accordo-Scuola
          </router-link>
          <span v-else>
            <v-alert
              class="alert"
              width="800"
              type="warning"
              v-if="programmaOrientamento == null"
              v-html="
                'Non è possibile aggiungere un accordo-scuola se non hai creato un <b> PROGRAMMA DI ORIENTAMENTO</b>.'
              "
            />
          </span>
        </v-col>
      </v-row>

      <v-card-text>
        <div v-if="scuoleAccordi.length > 0">
          <v-row class="my-4">
            <v-col col="8">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per ID Accordo, codice  o nome istituto"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="tableScuoleAccordi"
            :items="scuoleAccordi"
            :search="ricerca"
            :loading="!permessiOperazione"
            :show-expand="checkAnno"
            :single-expand="checkAnno"
          >
            <!-- eslint-disable -->
            <template v-slot:item.cup="{ item }">
              <span> {{ programmaOrientamento.cup }}</span>
            </template>
            <template v-if="anno > 2023" v-slot:item.dataFineAccordo="{ item }">
              <span>{{formatDate(item.dataFineAccordo)}}</span>
            </template>
            <template v-if="anno > 2023" v-slot:item.dataFirmaAccordo="{ item }">
              <span>{{formatDate(item.dataFirmaAccordo)}}</span>
            </template>
            <!--<template v-slot:item.istituto="{ item }">
              <span
                  v-html="
                    item.istituto.codiceScuola +
                    ' - ' +
                    item.istituto.denominazioneScuola
                  "
              />
            </template>-->
                  <template v-if="anno < 2024" v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <ElencoSottoAccordi
                              :capofila="capofila"
                              :itemsSottoAccordi="item.sottoAccordiScuola"
                              :idScuolaAccordo="item.id"
                              :finestra-compilazione-attiva="finestraCompilazioneAttiva"
                        >

                        </ElencoSottoAccordi>
                      </td>
                  </template>
            <template v-slot:item.fileAccordo="{ item }">
               <span v-if="item.fileAccordo != null">
                  <a @click="downloadPDF(item, item.fileAccordo.nomeOriginale)">
                    <v-icon>mdi-paperclip</v-icon>
                  </a>
                </span>
              <span v-else v-html="'Pdf non caricato'"></span>
            </template>
            <template v-slot:item.azioni="{ item }">
              <router-link
                  v-if="permessiOperazione > 0 && checkControlloCorsiTerminati(item, 1) && finestraCompilazione"
                  :to="{
                    name: 'aggiungi-scuola-accordi',
                    params: {
                      capofila: capofila,
                      idAccordo: item.id,
                      operazione: 'modifica',
                    },
                  }"
              >
                <span> <v-icon> mdi-square-edit-outline </v-icon> </span>
              </router-link>
              <router-link
                  v-else
                  :to="{
                    name: 'aggiungi-scuola-accordi',
                    params: {
                      capofila: capofila,
                      idAccordo: item.id,
                      operazione: 'lettura',
                    },
                  }"
              >
                <span> <v-icon> mdi-eye </v-icon></span>
              </router-link>
            </template>
              <template v-if="anno <= 2023" v-slot:item.integra="{ item }">
                   <v-list-item
                     v-if="checkControlloCorsiTerminati(item, 2) && finestraCompilazione"
                      :to="{
                           name: 'aggiungiSottoAccordo',
                           params: {
                             capofila: capofila,
                             idAccordo: item.id,
                             operazione: 'inserimento',
                           },
                      }">
                    <span><v-icon> mdi-square-edit-outline </v-icon>  </span>
                  </v-list-item>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon> mdi-square-edit-outline </v-icon>
                      </v-list-item>
                      </template>
                    <span>
                      L’inserimento dell’integrazione sarà possibile solo se la somma tra il <br />
                      valore del campo 'numero_corsi' nell'accordo-scuola specifico e <br /> i valori
                      del campo omonimo di tutti i sotto-accordi associati è uguale <br/> al numero
                      dei corsi terminati totali, in riferimento all'accordo-scuola specifico.
                    </span>
                  </v-tooltip>
              </template>
            <!-- eslint-enable -->
          </v-data-table>
        </div>
        <div v-else>
          <span> Non esistono accordi al momento per l'istituto scelto </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-alert
        class="alert"
        width="800"
        type="warning"
        v-if="programmaOrientamento == null"
        v-html="
          'Non è possibile aggiungere un accordo-scuola se non hai creato un <b> PROGRAMMA DI ORIENTAMENTO</b>.'
        "
      />
    </v-card>
  </v-container>
</template>

<script>
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import ElencoSottoAccordi from "@/components/ElencoSottoAccordi.vue";
import { mapGetters, mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "ScuoleAccordi",
  components: { BreadcrumbComponent, ElencoSottoAccordi },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleReferenteCorsi",
      "isRoleLegaleProgramma",
      "isRoleReferenteProgramma",
      "isRoleReferenteIstituto",
      "isRoleConsulenza",
      "isRoleLegale",
      "isReadOnly",
      "isRoleReferenteIstitutoCorso",
    ]),
    checkAnno() {
      return this.anno < 2024;
    },
    tableScuoleAccordi() {
      let headers = [
        { text: "ID ACCORDO", value: "id", align: "start" },
        {
          text: "CODICE ISTITUTO SCOLASTICO",
          value: "istituto.codiceScuola",
          align: "start",
        },
        {
          text: "ISTITUTO SCOLASTICO",
          value: "istituto.denominazioneScuola",
          align: "start",
        },
        { text: "NUMERO ALUNNI", value: "numeroAlunni" },
        { text: "NUMERO CORSI", value: "numeroCorsi" },
        { text: "PDF ACCORDO", sortable: false, value: "fileAccordo" },
      ];

      if (this.anno < 2024) {
        headers.push({ text: "INTEGRA", sortable: false, value: "integra" });
        headers.push({
          text: "SOTTO-ACCORDI",
          sortable: false,
          value: "data-table-expand",
        });
      }
      if (this.anno > 2023) {
        headers.push({
          text: "DATA FIRMA ACCORDO",
          sortable: true,
          value: "dataFirmaAccordo",
        });
        headers.push({
          text: "DATA FINE ACCORDO",
          sortable: true,
          value: "dataFineAccordo",
        });
      }
      headers.push({
        text: "MODIFICA/VISUALIZZA",
        sortable: false,
        value: "azioni",
      });
      return headers;
    },
    loading: function () {
      return this.loadingPage;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Scuole-Accordi",
            disabled: true,
            to: `/scrivania/${this.capofila}/scuole-accordi`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.inizializzaTable();
    },
    anno() {
      this.inizializzaTable();
    },
  },
  created() {},
  data: () => ({
    ricerca: null,
    finestraCompilazione: null,
    finestraCompilazioneAttiva: true,
    scuoleAccordi: [],
    programmaOrientamento: null,
    disabledAggiuntaAccordoBtn: false,
    loadingPage: false,
    expanded: [],
    permessiOperazione: null,
  }),
  mounted() {
    this.inizializzaTable();
  },
  methods: {
    async inizializzaTable() {
      this.loadingPage = false;
      this.checkPermessiOperazioni();
      let anno = this.$store.state.anno;
      let idStrutture = this.$store.state.idStrutture;
      this.getFinestraCompilazione(idStrutture, anno);
      await this.getProgrammaOrientamento(idStrutture, anno);
    },
    async getFinestraCompilazione(idStrutture, anno) {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          idStrutture,
          anno,
          "accordi-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
      this.finestraCompilazioneAttiva = !!this.finestraCompilazione;
    },
    async getScuoleAccordi(struttura, anno) {
      if (anno && struttura) {
        ScuoleAccordoRepository.getScuoleAccordi(
          struttura,
          anno,
          this.capofila
        ).then((data) => {
          this.scuoleAccordi = data.length > 0 ? data : [];
        });
      }
    },
    formatDate(data) {
      if (data) {
        return new Date(data).toLocaleDateString("it-IT");
      }
      return "";
    },
    async downloadPDF(scuolaAccordo, nomeFileOriginale) {
      return ScuoleAccordoRepository.downloadPDFAccordo(
        this.idStrutture,
        scuolaAccordo.id,
        nomeFileOriginale
      );
    },
    async getProgrammaOrientamento(idStrutture, anno) {
      ProgrammiRepository.getProgrammi(idStrutture, anno, this.capofila).then(
        (data) => {
          if (data.length > 0) {
            this.programmaOrientamento = data[0];
            this.getScuoleAccordi(idStrutture, anno);
            this.loadingPage = true;
          } else {
            this.programmaOrientamento = null;
            this.loadingPage = false;
            this.disabledAggiuntaAccordoBtn = true;
          }
        }
      );
    },
    checkPermessiOperazioni() {
      if (
        this.isRoleReferenteProgramma ||
        this.isRoleConsulenza ||
        this.isRoleLegale
      ) {
        return (this.permessiOperazione = 1);
      } else if (this.isReadOnly) {
        return (this.permessiOperazione = -1);
      } else if (this.isRoleReferenteCorsi) {
        return (this.permessiOperazione = -1);
      } else {
        return (this.permessiOperazione = 1);
      }
    },
    checkControlloCorsiTerminati(accordo, tipologiaControllo) {
      var ret = false;

      switch (tipologiaControllo) {
        case 1:
          // controllo che almeno uno dei corsi associati all'accordo sia terminato
          ret = !accordo.corsi.some((corso) => corso.terminato);
          break;
        case 2:
          /* controllo che il numero dei corsi terminati 
          sia uguale al totale dei corsi previsti dalla scuole-accordo e dai sotto-accordi
          */

          if (this.anno === 2022) {
            if (accordo.sottoAccordiScuola.length > 0) {
              const sumNCorsiSottoAccordi = accordo.sottoAccordiScuola.reduce(
                (v, item) => {
                  return v + item.numeroCorsi;
                },
                0
              );
              ret =
                accordo.totCorsiTerminati ===
                  accordo.numeroCorsi + sumNCorsiSottoAccordi &&
                accordo.fileAccordo;
            } else {
              ret =
                accordo.totCorsiTerminati === accordo.numeroCorsi &&
                accordo.fileAccordo;
            }
          } else {
            ret = true;
          }
          break;
      }
      return ret;
    },
  },
};
</script>

<style scoped>
.v-card-text {
  text-align: center;
  margin: 0 auto;
}
</style>
