<template>
  <v-container>
    <BreadcrumbComponent
      :breadcrumbs="breadcrumbs"
      v-if="capofila && !isPartner"
    />
    <v-btn class="primary" @click="$router.go(-1)" v-else>
      <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
    </v-btn>
    <v-row>
      <v-col class="pt-4">
        <AlertErroriCfCorso
          v-if="idCorso"
          :id-corso="idCorso"
        ></AlertErroriCfCorso>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-4">
        <template>
          <v-alert dense v-if="terminato" type="info" density="compact">
            Non è possibile effettuare modifiche poichè il corso risulta
            TERMINATO.
          </v-alert>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="8"
          class="mx-auto mt-8 px-6 pb-6"
          :loading="loadingForm"
          v-if="isRoleReferenteProgramma || isRoleConsulenza"
        >
          <v-card-title class="h4"
            >Sostituisci il referente di corso
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="idNuovoReferente"
              label="Seleziona il referente"
              :items="referentiCorso"
              :item-text="nominativoCompleto"
              item-value="id"
              :disabled="terminato"
            ></v-autocomplete>
            <v-row>
              <v-col cols="12" class="d-flex flex-row-reverse">
                <template v-if="terminato || !finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" large>mdi-close-octagon</v-icon>
                    </template>
                    <span v-if="terminato"
                      >Non è possibile sostituire il referente di un corso
                      terminato!</span
                    ><span v-else-if="!finestraCompilazione"
                      >Finestra di compilazione chiusa</span
                    >
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-btn
                    class="primary"
                    @click="dialogSostituisciReferente = true"
                  >
                    Sostituisci
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card elevation="8" class="mx-auto mt-8 px-6 pb-6" :loading="loadingForm">
      <v-card-title class="h1"> Censimento corsi </v-card-title>
      <div class="pa-4">
        <div class="my-4">
          <small> I campi contrassegnati con * sono obbligatori </small>
        </div>
        <v-form ref="form">
          <v-row class="mt-8">
            <v-col><h5 class="text-uppercase">seleziona la scuola *</h5></v-col>
          </v-row>
          <v-row v-if="anno === 2022">
            <v-col>
              <!-- lista dropdown solo per anno 2022 -->
              <v-autocomplete
                v-model="corso.accordo"
                :items="istitutiScolastici"
                :item-text="testoCompleto"
                item-value="id"
                :loading="loadingForm"
                label="Seleziona istituto..."
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="setDatiScuola(corso.accordo)"
                :disabled="readOnly"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <!-- lista dropdown istituti inseriti da referente -->
              <v-autocomplete
                v-model="corso.accordi"
                :items="istitutiScolastici"
                :item-text="testoCompleto"
                item-value="id"
                :loading="loadingForm"
                label="Seleziona istituto..."
                multiple
                required
                :rules="validazioneAccordi()"
                @input="validateField"
                @change="setDatiScuola(corso.accordi)"
                :disabled="readOnly"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col><h5 class="text-uppercase">dati del corso</h5></v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Anno scolastico di riferimento *"
                v-model="this.annoScolastico"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="
                corsiStruttura && corsiStruttura.length > 0 && anno === 2022
              "
              cols="4"
            >
              <v-autocomplete
                v-model="corso.corsiAssociati"
                :items="corsiStruttura"
                item-value="id"
                :item-text="testoCorsi"
                outlined
                hide-no-data
                chips
                small-chips
                label="Corsi associati"
                multiple
                :readonly="readOnly"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" v-else>
              <div v-if="anno === 2022">
                <v-progress-linear
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="alertNoFirmaAccordo && anno > 2023">
            <v-col class="pt-4">
              <template>
                <v-alert dense type="warning" density="compact">
                  ATTENZIONE: Si ricorda che la data di inizio del corso dovrà
                  essere successiva alla data di firma dell'accordo.
                </v-alert>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="alertErroreDataInizioCorso && anno > 2023">
            <v-col class="pt-4">
              <template>
                <v-alert dense type="error" density="compact">
                  ERRORE: La data di inizio del corso non può essere antecedente
                  la data di firma dell'accordo. (Data firma accordo:
                  {{ this.dataFirmaAccordo | formatDate }})
                </v-alert>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Regione *"
                v-model="corso.regione"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Data inizio prevista *"
                type="date"
                v-model="corso.dataInizio"
                :rules="validazioneCampoDataInizioFine()"
                @input="validateField"
                @change="setAlertDataInizio"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Data fine revista *"
                type="date"
                v-model="corso.dataFine"
                :rules="validazioneCampoDataInizioFine()"
                @input="validateField"
                @change="validateField"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="N° Docenti dell'Istituto Scolastico *"
                v-model="corso.numeroDocenti"
                :rules="validazioneNumeroDocenti(corso.numeroDocenti)"
                @input="validateField"
                @change="validateField"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="N° studenti da coinvolgere *"
                v-model.number="corso.numeroStudenti"
                :rules="validazioneNumerica()"
                @input="validateField"
                @change="validateField"
                @keyup="controllaAlunni"
                :readonly="readOnly"
              ></v-text-field>
              <v-row>
                <v-col>
                  <small class="mt-0 orange--text" v-if="superatoTargetAlunni"
                    >L' inserimento di studenti oltre il target non assicura che
                    saranno finanziati se l’incremento non è approvato dal
                    MUR</small
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <!-- numero compreso tra 0 e N° studenti da coinvolgere -->
              <v-text-field
                label="N° studenti con disabilità e DSA *"
                v-model.number="corso.numeroStudentiDsa"
                :rules="validazioneStudentiDSA(corso.numeroStudenti)"
                @input="validateField"
                @change="validateField"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <!--No menù a tendina, campo precompilato non modificabile -->
              <v-text-field
                label="Nominativo referente del corso - Università/AFAM *"
                v-model="this.nominativoReferente"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Cognome referente del corso - Istituto scolastico *"
                v-model="corso.cognomeReferenteScolastico"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Nome referente del corso - Istituto scolastico *"
                v-model="corso.nomeReferenteScolastico"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row class="mt-8">
            <v-col cols="6"
              ><h5 class="text-uppercase">Format del corso *</h5></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="corso.tipoFormazione"
                label="Tipologia di formazione erogata:"
                row
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
                :readonly="readOnly"
              >
                <v-radio
                  v-for="tipo in tipiFormazione"
                  :key="tipo.id"
                  :label="tipo.descrizione"
                  :value="tipo"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col>
              <v-radio-group
                v-model="corso.tipoAttivita"
                label="Tipologia di attività:"
                row
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
                :readonly="readOnly"
              >
                <v-radio
                  v-for="tipo in tipiAttivita"
                  :key="tipo.id"
                  :label="tipo.descrizione"
                  :value="tipo"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mt-4 pa-4">
            <v-textarea
              v-model="corso.dettagliCorso"
              outlined
              :rules="validazioneDettaglioCorso()"
              @input="validateField"
              @change="validateField"
              :readonly="readOnly"
              class="mb-0"
              hint="Massimo 500 caratteri"
              name="dettagliCorso"
              label="Dettagli contenuto del corso *"
              counter
            ></v-textarea>
          </v-row>
          <v-row class="mt-8 px-2">
            <v-col cols="6"
              ><h5 class="text-uppercase">Dettaglio del format *</h5></v-col
            >
          </v-row>
          <v-row align="center" class="px-5 pt-0">
            <v-col cols="1"
              ><v-checkbox
                v-model="corso.dettaglioFormat1"
                :value="corso.dettaglioFormat1"
                hide-details
                class="shrink mr-2 mt-0"
                :readonly="readOnly"
              ></v-checkbox
            ></v-col>
            <v-col cols="9"
              ><span>
                Conoscere il contesto della formazione superiore e del suo
                valore in una società della conoscenza, informarsi sulle diverse
                proposte formative quali opportunità per la crescita personale e
                la realizzazione di società sostenibili e inclusive
              </span></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model.number="corso.valoreDettaglioFormat1"
                :disabled="!corso.dettaglioFormat1"
                label="Rilevanza"
                suffix="%"
                :readonly="readOnly"
                @keyup="calcolatoreRilevanze"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row align="center" class="px-5 pt-0">
            <v-col cols="1"
              ><v-checkbox
                v-model="corso.dettaglioFormat2"
                :value="corso.dettaglioFormat2"
                hide-details
                class="shrink mr-2 mt-0"
                :readonly="readOnly"
              ></v-checkbox
            ></v-col>
            <v-col cols="9"
              ><span>
                Fare esperienza di didattica disciplinare attiva, partecipativa
                e laboratoriale, orientatadalla metodologia di apprendimento del
                metodo scientifico
              </span></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model.number="corso.valoreDettaglioFormat2"
                :disabled="!corso.dettaglioFormat2"
                label="Rilevanza"
                suffix="%"
                :readonly="readOnly"
                @keyup="calcolatoreRilevanze"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row align="center" class="px-5 pt-0">
            <v-col cols="1"
              ><v-checkbox
                v-model="corso.dettaglioFormat3"
                :value="corso.dettaglioFormat3"
                hide-details
                class="shrink mr-2 mt-0"
                :readonly="readOnly"
              ></v-checkbox
            ></v-col>
            <v-col cols="9"
              ><span>
                Autovalutare, verificare e consolidare le proprie conoscenze per
                ridurre il divario tra quelle possedute e quelle richieste per
                il percorso di studio di interesse
              </span></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model.number="corso.valoreDettaglioFormat3"
                :disabled="!corso.dettaglioFormat3"
                label="Rilevanza"
                suffix="%"
                :readonly="readOnly"
                @keyup="calcolatoreRilevanze"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row align="center" class="px-5 pt-0">
            <v-col cols="1"
              ><v-checkbox
                v-model="corso.dettaglioFormat4"
                :value="corso.dettaglioFormat4"
                hide-details
                class="shrink mr-2 mt-0"
                :readonly="readOnly"
              ></v-checkbox
            ></v-col>
            <v-col cols="9"
              ><span>
                Consolidare competenze riflessive e trasversali per la
                costruzione del progetto di sviluppo formativo e professionale
              </span></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model.number="corso.valoreDettaglioFormat4"
                :disabled="!corso.dettaglioFormat4"
                label="Rilevanza"
                suffix="%"
                :readonly="readOnly"
                @keyup="calcolatoreRilevanze"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row align="center" class="px-5 pt-0">
            <v-col cols="1"
              ><v-checkbox
                v-model="corso.dettaglioFormat5"
                :value="corso.dettaglioFormat5"
                hide-details
                class="shrink mr-2 mt-0"
                :readonly="readOnly"
              ></v-checkbox
            ></v-col>
            <v-col cols="9"
              ><span>
                Conoscere i settori del lavoro, gli sbocchi occupazionali
                possibili nonché i lavori futuri sostenibili e inclusivi e il
                collegamento fra questi e le conseguenze e competenze acquisite
              </span></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model.number="corso.valoreDettaglioFormat5"
                :disabled="!corso.dettaglioFormat5"
                label="Rilevanza"
                suffix="%"
                :readonly="readOnly"
                @keyup="calcolatoreRilevanze"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <small
                class="mt-0 red--text"
                v-if="!isNumeroRilevanzeValido && !readOnly"
                >ATTENZIONE: è necessario inserire almeno un dettaglio format e
                la somma delle rilevanze non può essere diversa dal 100%</small
              >
            </v-col>
          </v-row>

          <v-row class="mt-8" v-if="idCorso">
            <v-col cols="12">
              <v-alert color="blue" type="info"
                ><span
                  >Per re-inserire studenti che non hanno raggiunto il 70% della
                  frequenza in un corso precedente è necessario utilizzare
                  esclusivamente la funzionalità "Aggiungi studente"
                </span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-4">
              <AlertCensimentoStudenti
                v-if="idCorso && anno !== 2022"
                :id-corso="idCorso"
                :key="'stud_' + keyStudenti"
              ></AlertCensimentoStudenti>
            </v-col>
          </v-row>
          <v-row class="mt-2" v-if="idCorso">
            <v-col cols="6"
              ><h5 class="text-uppercase">Studenti del corso</h5>
            </v-col>
            <v-col cols="6" class="d-flex flex-row-reverse">
              <dialog-aggiungi-studente
                :id-corso="this.idCorso"
                :update-corso="aggiornaCorso"
                @studente-aggiunto="keyStudenti += 1"
                v-if="!readOnly"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8" v-if="idCorso">
            <v-col cols="6"> </v-col>
            <v-col cols="6" class="d-flex flex-row-reverse">
              <DialogAggiungiStudenteDaFile
                :id-corso="this.idCorso"
                @studenti-da-file-aggiunti="keyStudenti += 1"
                v-if="!readOnly"
              />
            </v-col>
          </v-row>
          <StudentiDelCorso
            v-if="idCorso"
            :id-corso="idCorso"
            :terminato="corso.terminato"
            :finestra-compilazione-attiva="finestraCompilazioneAttiva"
            @studente-eliminato="keyStudenti += 1"
            :key="'stud_' + keyStudenti"
          ></StudentiDelCorso>

          <v-row class="mt-8" v-if="idCorso">
            <v-col cols="6"
              ><h5 class="text-uppercase">Docenti del corso</h5></v-col
            >
            <v-col cols="6" class="d-flex flex-row-reverse">
              <dialog-aggiungi-docente
                :id-corso="this.idCorso"
                @docente-aggiunto="keyDocenti += 1"
                v-if="!readOnly"
              />
            </v-col>
          </v-row>
          <DocentiDelCorso
            v-if="idCorso"
            :id-corso="idCorso"
            :terminato="corso.terminato"
            :finestra-compilazione-attiva="finestraCompilazioneAttiva"
            :key="'doc_' + keyDocenti"
          ></DocentiDelCorso>

          <v-row>
            <v-col class="d-flex flex-row-reverse">
              <v-btn
                class="primary"
                @click="dialogSalva = true"
                v-if="!readOnly"
              >
                Salva
              </v-btn>
            </v-col>
          </v-row>
          <v-main class="mt-3">
            <v-row>
              <v-col cols="8" />
              <v-col cols="4">
                <v-alert
                  v-if="alertAggiuntaSottoAccordo && anno !== 2022"
                  max-width="800"
                  type="warning"
                >
                  ATTENZIONE: Il numero di corsi creati ha superato il numero di
                  corsi dichiarati nell’accordo con la scuola selezionata. Si
                  invita ad integrare l'accordo con la scuola nella card
                  "Inserimento Scuole - Accordi".
                </v-alert>
              </v-col>
            </v-row>
          </v-main>
        </v-form>
      </div>
      <dialog-conferma
        @callback="updateCorso"
        :text-conferma="getTextConferma()"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />
      <dialog-conferma
        @callback="UpdateReferenteCorso"
        :dialog.sync="dialogSostituisciReferente"
        @close-dialog="dialogSostituisciReferente = false"
      />

      <v-snackbar v-model="snackbar" shaped :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import DialogAggiungiStudente from "@/components/DialogAggiungiStudente";
import DialogAggiungiStudenteDaFile from "@/components/DialogAggiungiStudenteDaFile.vue";
import DialogAggiungiDocente from "@/components/DialogAggiungiDocente";
import StudentiDelCorso from "@/components/StudentiDelCorso";
import DocentiDelCorso from "@/components/DocentiDelCorso";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
//import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
//import IstitutiScolasticiRepository from "@/api/istituto/IstitutiScolasticiRepository";
import AlertErroriCfCorso from "@/components/AlertErroriCfCorso";
import AlertCensimentoStudenti from "@/components/AlertCensimentoStudenti";

export default {
  name: "ModificaCensimentoCorsiView",
  components: {
    AlertErroriCfCorso,
    DialogConferma,
    BreadcrumbComponent,
    DialogAggiungiStudente,
    StudentiDelCorso,
    DialogAggiungiDocente,
    DocentiDelCorso,
    DialogAggiungiStudenteDaFile,
    AlertCensimentoStudenti,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorsoOuter: {
      type: Number,
      required: false,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "ISTITUTO SCOLASTICO",
        align: "start",
        sortable: false,
        value: "istituto.denominazioneScuola",
      },
      {
        text: "CODICE MECCANOGRAFICO",
        value: "istituto.codiceScuola",
      },
      {
        text: "TIPOLOGIA ISTITUTO",
        value: "istituto.tipologiaGradoIstruzioneScuola",
      },
      { text: "REGIONE", value: "istituto.regione" },
      { text: "CITTÀ", value: "istituto.descrizioneComune" },
    ],
    aggiornaCorso: 0,
    corsiStruttura: [],
    corsiAssociati: [],
    terminato: false,
    rivalidatoCf: 0,
    newCodiceMeccanografico: false,
    idNuovoReferente: null,
    referentiCorso: [],
    totaleAlunniCorsi: 0,
    tuttiCorsi: [],
    superatoTargetAlunni: false,
    isMioCorso: null,
    utente: null,
    idAccordo: null,
    campoRegione: null,
    istitutiScolastici: [],
    keyStudenti: 0,
    keyDocenti: 0,
    dialogAggiungiStudente: null,
    dialogSostituisciReferente: null,
    accordo: null,
    idCorso: null,
    tipiFormazione: null,
    tipiAttivita: null,
    loadingForm: true,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    annoScolastico: null,
    programma: [],
    sommaRilevanze: 0,
    isNumeroRilevanzeValido: false,
    nominativoReferente: null,
    finestraCompilazione: null,
    finestraCompilazioneAttiva: true,
    corso: {
      accordi: [],
      accordo: null,
      cup: null,
      idScuola: 0,
      annoScolastico: 0,
      dataInizio: null,
      dataFine: null,
      referente: null,
      numeroStudenti: null,
      numeroStudentiDsa: null,
      tipoFormazione: null,
      tipoAttivita: null,
      dettagliCorso: null,
      dettaglioFormat1: 0,
      dettaglioFormat2: 0,
      dettaglioFormat3: 0,
      dettaglioFormat4: 0,
      dettaglioFormat5: 0,
      valoreDettaglioFormat1: 0,
      valoreDettaglioFormat2: 0,
      valoreDettaglioFormat3: 0,
      valoreDettaglioFormat4: 0,
      valoreDettaglioFormat5: 0,
      regione: null,
      cognomeReferenteScolastico: null,
      nomeReferenteScolastico: null,
      numeroDocenti: null,
      corsiAssociati: [],
    },
    alertAggiuntaSottoAccordo: false,
    alertNoFirmaAccordo: false,
    alertErroreDataInizioCorso: false,
    dataFirmaAccordo: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleLegale",
      "isRoleLegaleCorso",
      "isRoleConsulenza",
      "isRoleReferenteProgramma",
      "isRoleReferenteIstituto",
      "isRoleReferenteIstitutoCorso",
    ]),
    readOnly() {
      if (this.isReadOnly || this.terminato) return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      } else {
        if (
          //(this.isRoleReferenteIstitutoCorso && !this.corso.referente) ||
          (this.isRoleLegaleCorso && !this.corso.referente) ||
          this.isMioCorso
        ) {
          return false;
        }
        if (
          this.isRoleReferenteIstituto ||
          this.isRoleReferenteProgramma ||
          this.isRoleLegale
        ) {
          return true;
        }
      }
      return false;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento corsi, erogazione formazione e certificati",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento corsi",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi/censimento-corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Inserisci/Modifica",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi/censimento-corsi/inserisci`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    rivalidatoCf() {
      this.initialize();
    },
    regione() {
      this.initialize();
    },
    user() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
    this.annoScolastico = this.anno + "/" + (this.anno + 1);
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loadingForm = true;
        this.getIstitutiScolatici();
        this.nominativoReferente = this.user.name;
        if (this.idCorsoOuter) {
          this.idCorso = this.idCorsoOuter;
          this.getCorso();
          this.isNumeroRilevanzeValido = true;
        }
        this.getReferentiCorso();
        this.getFinestraCompilazione();
        this.getTipiFormazione();
        this.getTipiAttivita();
        this.getAlunniInseriti();
        this.getCorsiStruttura();
        this.loadingForm = false;
      }
    },
    validateField() {
      this.validate =
        this.$refs.form.validate() && this.isNumeroRilevanzeValido;
    },
    validazioneNumeroDocenti() {
      return [
        (v) => v !== "" || "Il campo è obbligatorio.",
        (v) =>
          (v !== "" && v >= 0) ||
          "Il valore del campo non può essere vuoto o  negativo",
        (v) =>
          !(Number(v) === v && v % 1 !== 0) ||
          "Non è consentito inserire cifre decimali.",
      ];
    },
    validazioneNumerica() {
      return [
        (v) =>
          (v || "") >= 1 ||
          `Il campo è obbligatorio e non può essere uguale a 0.`,
        (v) =>
          !(Number(v) === v && v % 1 !== 0) ||
          "Non è consentito inserire cifre decimali.",
      ];
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    validazioneCampoDataInizioFine() {
      return [
        (v) => !!v || "Campo richiesto",
        (v) => {
          if (!v) return true; // Se il campo è vuoto, la validazione passa
          const selectedDate = new Date(v);
          const minDate = new Date(`${this.anno}-09-01`); // 1° settembre dell'anno accademico corrente
          const maxDate = new Date(`${this.anno + 1}-08-31`); // 31 agosto dell'anno successivo (ma lo stesso anno accademico)
          if (selectedDate < minDate) {
            return `Inserire una data successiva al 1° settembre ${this.anno}`;
          } else if (selectedDate > maxDate) {
            return `Inserire una data precedente al 31° Agosto ${
              this.anno + 1
            }`;
          }
          return true;
        },
      ];
    },
    validazioneStudentiDSA(val) {
      return [
        (v) =>
          (v !== null && v <= val) ||
          `Il campo è obbligatorio e il valore inserito non può essere superiore al numero degli studenti coinvolti`,
      ];
    },
    validazioneDettaglioCorso() {
      return [
        (v) =>
          (v && v.length < 500) ||
          "Il campo è obbligatorio e non può superare i 500 caratteri",
      ];
    },
    validazioneAccordi() {
      return [(v) => !!v.length || "Seleziona almeno una voce"];
    },
    calcolatoreRilevanze() {
      this.sommaRilevanze = Number(
        (this.corso.valoreDettaglioFormat1
          ? Number(this.corso.valoreDettaglioFormat1)
          : 0) +
          (this.corso.valoreDettaglioFormat2
            ? Number(this.corso.valoreDettaglioFormat2)
            : 0) +
          (this.corso.valoreDettaglioFormat3
            ? Number(this.corso.valoreDettaglioFormat3)
            : 0) +
          (this.corso.valoreDettaglioFormat4
            ? Number(this.corso.valoreDettaglioFormat4)
            : 0) +
          (this.corso.valoreDettaglioFormat5
            ? Number(this.corso.valoreDettaglioFormat5)
            : 0)
      ).toFixed(0);
      this.isNumeroRilevanzeValido = Number(this.sommaRilevanze) === 100;
    },
    async setDatiScuola(idAccordo) {
      this.alertErroreDataInizioCorso = false;
      if (this.anno !== 2022) {
        const accordo = this.istitutiScolastici.find(
          (item) => item.id === idAccordo[0]
        );
        //this.controlloInserimentoCorso(idAccordo);
        this.corso.idScuola = accordo.istituto.id;
        this.corso.regione = accordo.istituto.regione;
        this.newCodiceMeccanografico = true;
      } else {
        const accordo = this.istitutiScolastici.find(
          (item) => item.id === idAccordo
        );
        let arrayAccordi = [];
        arrayAccordi.push(idAccordo);
        this.controlloInserimentoCorso(arrayAccordi);
        this.corso.idScuola = accordo.istituto.id;
        this.corso.regione = accordo.istituto.regione;
        this.newCodiceMeccanografico = true;
      }
    },
    async updateCorso() {
      this.validateField();
      if (this.validate) {
        let corso = { ...this.corso };

        // post file to server
        const formData = new FormData();
        formData.append("corso", JSON.stringify(corso));

        try {
          if (this.idCorsoOuter == null && this.idCorso == null) {
            /**
                controllo che il numero di corsi da erogare, memorizzati nell'accordo-scuola e nei relativi sotto-accordi
                 non sia maggiore o uguale a quelli già erogati;
                 -------------------------------------------------------------------------------------------------------
                                                       ATTENZIONE!
                MODIFICATO A PARTIRE DALL'ANNO ACCADEMICO 2023, dove è sempre permesso l'inserimento di corsi
             */
            let arrayAccordi = [];
            if (corso.accordo && this.anno === 2022) {
              arrayAccordi.push(corso.accordo);
            } else {
              arrayAccordi = [...corso.accordi];
            }
            if (
              this.anno === 2022 &&
              !this.controlloInserimentoCorso(arrayAccordi)
            ) {
              /*!this.controlloInserimentoCorso(corso.accordo))*/ this.snackbarText =
                "Impossibile creare un nuovo corso di orientamento. " +
                "Il numero di corsi creati ha già raggiunto il numero di corsi inseriti nell’accordo con la scuola. " +
                "Per creare nuovi corsi sarà necessario integrare l’accordo con un sotto-accordo. ";
              this.snackbarColor = "red accent-2";
            } else {
              const newCorso = await CorsiRepository.createCorsoOrientamento(
                this.idStrutture,
                this.anno,
                this.capofila,
                formData
              );
              this.idCorso = newCorso.id;
              this.snackbarColor = "success";
              this.snackbarText = "Corso inserito con successo";
            }
          } else {
            if (this.newCodiceMeccanografico) {
              await this.rivalidazioneCf();
            }
            await CorsiRepository.updateCorsoOrientamento(
              this.idStrutture,
              this.anno,
              this.capofila,
              this.idCorsoOuter ?? this.idCorso,
              formData
            );
            this.snackbarText = "Corso aggiornato con successo";
            this.snackbarColor = "success";
            this.validate = true;
            this.initialize();
            this.aggiornaCorso += 1;
          }
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      } else {
        this.snackbarText =
          "Errore nella richiesta! Controllare di aver compilato i campi in modo corretto";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    async getTipiFormazione() {
      this.tipiFormazione = await CorsiRepository.getTipiFormazione();
    },
    async getTipiAttivita() {
      this.tipiAttivita = await CorsiRepository.getTipiAttivita();
    },
    async getCorso() {
      this.corso = await CorsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      let dataInizioFormattata;
      dataInizioFormattata = this.corso.dataInizio.split("T");
      this.corso.dataInizio = dataInizioFormattata[0];
      let dataFineFormattata;
      dataFineFormattata = this.corso.dataFine.split("T");
      this.corso.dataFine = dataFineFormattata[0];
      this.nominativoReferente = this.corso.referente.anagrafica.cognome.concat(
        " ",
        this.corso.referente.anagrafica.nome
      );
      /*if (this.corso.accordo) {
        this.corso.accordi.push(this.corso.accordo);
      }*/
      this.terminato = !!this.corso.terminato;
      this.isMioCorso = this.corso.referente.id === this.user.id;
      if (this.anno > 2023) {
        this.dataFirmaAccordo = this.getDataMinFirmaAccordo(this.corso.accordi);
        if (this.dataFirmaAccordo) {
          const dataInizioCorso = new Date(this.corso.dataInizio);
          const dataFirmaAccordoObj = new Date(this.dataFirmaAccordo);
          if (dataInizioCorso < dataFirmaAccordoObj) {
            this.alertErroreDataInizioCorso = true;
          } else {
            this.alertNoFirmaAccordo = true;
          }
        }
      }
    },
    getDataMinFirmaAccordo(accordi) {
      if (accordi.length > 1) {
        accordi.reduce((minData, accordo) => {
          const dataCorrente = new Date(accordo.dataFirmaAccordo);
          return dataCorrente < minData ? dataCorrente : minData;
        }, new Date(accordi[0].dataFirmaAccordo));
      } else {
        return accordi[0].dataFirmaAccordo ?? null;
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
      this.finestraCompilazioneAttiva = !!this.finestraCompilazione;
    },
    async getIstitutiScolatici() {
      this.programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.istitutiScolastici =
        await CorsiRepository.getIstitutiScolasticiReferente(
          this.idStrutture,
          this.anno,
          this.capofila
        );
    },
    testoCompleto(item) {
      return `${item.istituto.codiceScuola} -- ${item.istituto.denominazioneScuola} -- ${item.istituto.tipologiaGradoIstruzioneScuola} -- ${item.istituto.regione} -- ${item.istituto.descrizioneComune}`;
    },
    testoCorsi(item) {
      return `${item.id} -- ${item.accordo.istituto.denominazioneScuola}`;
    },
    //PROGMINI-5741 più accordi per un singolo corso -> vado a calcolare tutti gli studenti di tutti gli accordi
    calcolaSommaCorsiErogabiliMultipli(accordi) {
      let sommaCorsi = 0;
      let sommaTotCorsi = 0;

      // Itera su ciascun accordo nell'array
      for (const idAccordo of accordi) {
        const accordo = this.istitutiScolastici.find(
          (element) => element.id === idAccordo
        );
        if (
          accordo.sottoAccordiScuola &&
          accordo.sottoAccordiScuola.length > 0
        ) {
          // Se ci sono sottoaccordi, calcola la somma dei loro corsi
          const sum = accordo.sottoAccordiScuola.reduce((v, element) => {
            return v + element.numeroCorsi;
          }, 0);
          sommaCorsi += sum + accordo.numeroCorsi;
          sommaTotCorsi += accordo.totCorsiErogati;
        } else {
          // Altrimenti, aggiungi semplicemente il numero di corsi dell'accordo
          sommaCorsi += accordo.numeroCorsi;
          sommaTotCorsi += accordo.totCorsiErogati;
        }
      }
      let controllo = sommaCorsi <= sommaTotCorsi;
      // ci metto l'ndicazione se deve apparire l'alert non bloccante
      this.alertAggiuntaSottoAccordo = !controllo;

      return this.anno !== 2022 ? true : controllo;
    },
    controlloInserimentoCorso(arrayAccordi) {
      if (arrayAccordi.length === 1) {
        const accordo = this.istitutiScolastici.find(
          (element) => element.id === arrayAccordi[0]
        );
        let controllo = false;
        if (
          accordo.sottoAccordiScuola &&
          accordo.sottoAccordiScuola.length > 0
        ) {
          const sum = accordo.sottoAccordiScuola.reduce((v, element) => {
            return v + element.numeroCorsi;
          }, 0);
          controllo = accordo.totCorsiErogati < sum + accordo.numeroCorsi;
        } else {
          controllo = accordo.totCorsiErogati < accordo.numeroCorsi;
        }
        // ci metto l'ndicazione se deve apparire l'alert non bloccante
        this.alertAggiuntaSottoAccordo = !controllo;

        return this.anno !== 2022 ? true : controllo;
      } else {
        this.calcolaSommaCorsiErogabiliMultipli(arrayAccordi);
      }
    },
    controllaAlunni() {
      let totaleAlunniProvvisorio =
        Number(this.corso.numeroStudenti) + Number(this.totaleAlunniCorsi);
      if (totaleAlunniProvvisorio > this.programma[0].numeroAlunniCoinvolti) {
        this.superatoTargetAlunni = true;
      } else {
        this.superatoTargetAlunni = false;
      }
    },
    async getAlunniInseriti() {
      this.tuttiCorsi = await CorsiRepository.getAllCorsi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      if (this.tuttiCorsi.length === 0) {
        this.totaleAlunniCorsi = this.programma[0].numeroAlunniCoinvolti;
      } else {
        this.totaleAlunniCorsi = this.tuttiCorsi.reduce(
          (accumulator, object) => {
            return accumulator + object.numeroStudenti;
          },
          0
        );
      }
    },
    async getReferentiCorso() {
      this.referentiCorso = await CorsiRepository.getReferentiCorsoStruttura(
        this.idStrutture
      );
      this.referentiCorso = this.referentiCorso.map((item) => {
        return item.abilitato ? item : null;
      });
    },
    nominativoCompleto(item) {
      const text = `${item.anagrafica.cognome} ${item.anagrafica.nome}`;
      return text;
    },
    async UpdateReferenteCorso() {
      try {
        await CorsiRepository.updateReferenteCorso(
          this.idStrutture,
          this.idCorso,
          this.idNuovoReferente
        );
        this.snackbarText = "Referente di corso sostituito con successo";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.initialize();
      } catch (e) {
        this.snackbarText =
          "Errore nella richiesta! Controllare che il referente sia un utente abilitato alla piattaforma";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
        console.log(e);
      }
    },
    async rivalidazioneCf() {
      await CorsiRepository.getRivalidazioneCfCorso(
        this.idStrutture,
        this.idCorsoOuter ?? this.idCorso
      );
      this.keyStudenti += 1;
      this.rivalidatoCf += 1;
    },
    async getCorsiStruttura() {
      this.corsiStruttura = await CorsiRepository.getAllCorsi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
    },
    getTextConferma() {
      if (this.idCorsoOuter == null && this.idCorso == null) {
        return this.alertAggiuntaSottoAccordo && this.anno !== 2022
          ? "ATTENZIONE! <br /> Il numero di corsi creati ha superato il numero di corsi dichiarati nell’accordo con la scuola selezionata. <br /> Si invita ad integrare l'accordo con la scuola nella card Inserimento Scuole - Accordi. <br /> Procedere comunque?"
          : "Desideri confermare l'operazione selezionata?";
      }
      return "Desideri confermare l'operazione selezionata?";
    },
    setAlertDataInizio() {
      const arrayAccordi = this.istitutiScolastici.filter((obj) =>
        this.corso.accordi.includes(obj.id)
      );
      this.dataFirmaAccordo = this.getDataMinFirmaAccordoInit(arrayAccordi);
      console.log(arrayAccordi);
      console.log(this.dataFirmaAccordo);
      if (this.dataFirmaAccordo) {
        const dataInizioCorso = new Date(this.corso.dataInizio);
        if (dataInizioCorso < this.dataFirmaAccordo) {
          this.alertErroreDataInizioCorso = true;
        } else {
          this.alertErroreDataInizioCorso = false;
        }
      } else {
        this.alertNoFirmaAccordo = true;
      }
    },
    getDataMinFirmaAccordoInit(accordi) {
      if (accordi.length > 1) {
        return accordi.reduce((minData, accordo) => {
          const dataCorrente = new Date(accordo.dataFirmaAccordo);
          return dataCorrente < minData ? dataCorrente : minData;
        }, new Date(accordi[0].dataFirmaAccordo));
      } else {
        return accordi[0].dataFirmaAccordo
          ? new Date(accordi[0].dataFirmaAccordo)
          : null;
      }
    },
  },
};
</script>

<style scoped></style>
